import { RefObject } from "react";
import { apiHeaderType, AlertIcType } from "../types";
import { ErrorHandleHook } from "../functions";



// const { signal: sgnl } = new AbortController();
const headR = (tok?: string, xHeader?: boolean) => {
    var obj: apiHeaderType = { "Content-Type": 'application/json' };
    tok && (obj = { ...obj, "Authorization": `Bearer ${tok}` });
    obj = { ...obj, "Ocp-Apim-Subscription-Key": `8de0436ddcdb4715b42d6be055a467ae` };
    // xHeader && (obj = { ...obj, "X-Originator": headerX_Originator });
    return obj;
};

const showAlert = (ref?: RefObject<AlertIcType>, res?: Response, code?: number, resJSON?: any) => {
    let notFound404;
    if (code === 404 || code === 400) {
        // notFound404 = typeof response?.body === "string" && JSON.parse(response?.body);
        console.log(typeof resJSON, JSON.stringify(resJSON));
        notFound404 = JSON?.parse(resJSON)
        // typeof resJSON === "string" && (notFound404 = JSON.parse(resJSON));
    }
    const errorRes = resJSON?.result?.toString()?.replace(/,/g, " ");

    const { sCode, eMessage, /* msg */ } = ErrorHandleHook(
        code || 500,
        resJSON?.title || resJSON?.result?.message || resJSON?.result || errorRes || notFound404?.result || res?.statusText || "somethingWentWrong",
        res?.statusText
    );
    // console.warn("\n", "resJSON__title", resJSON?.title, "\n", "resJSON__result", resJSON?.result, "\n", "resJSON__result__message", resJSON?.result?.message, "\n", "errorRes", errorRes, "\n", "notFound404", notFound404?.result, "\n", "res__statusText", res?.statusText, "\n", "sCode", sCode, "\n", "eMessage", eMessage, "\n", "msg", msg, "\n");

    if (sCode === 200 || sCode === 202) { }
    else {
        ref?.current && ref.current?.onErr({
            titleLocal: /* sCode === 401 ? "errUnAuth" : */ "err",
            body: (/* sCode === 401 ||  */sCode === 403) ? undefined : eMessage,
            bodyLocal: (/* sCode === 401 || */ sCode === 403) ? eMessage : /* sCode === 404 ? msg404 : */ undefined,
            /* logOut: sCode === 401, */
            // goBack: noBack === true && sCode === 404,
            btnTxtLocal: /* sCode === 401 ? "logoutText" : */ "ok",
        });
    };
};

const GETreq = async (path: string, token?: string | undefined, toText?: boolean, opts?: { /* signal: AbortSignal; */ ref?: RefObject<AlertIcType> }, myURL?: string) => {
    // var reqObj: RequestInit = token ? { method: 'GET', headers: headR(token)/* , signal */ } : { method: 'GET'/* , signal */ };
    let raw = myURL ? { method: 'GET' } : { method: 'GET', headers: headR(token), /* signal */ };

    let res = await fetch(myURL || path, raw);
    let resJSON;
    if (res !== undefined && (res.status === 200 || res.status === 202)) {
        resJSON = toText ? await res?.text() : await res?.json() || await res?.text();
    } else resJSON = await res?.text();
    opts?.ref !== undefined && showAlert(opts?.ref, res, res?.status, resJSON);
    return { code: res?.status, res: resJSON };
};

const POSTreq = async (path: string, body?: any, token?: string, toText?: boolean, opts?: { /* signal: AbortSignal; */ ref?: RefObject<AlertIcType>, xHeader?: boolean }) => {
    // var signal = opts?.signal || sgnl;
    var xHeader = opts?.xHeader || false;
    let raw = { method: 'POST', body: JSON.stringify(body), headers: headR(token, xHeader), /* signal */ };
    let rawNoBody = { method: 'POST', headers: headR(token, xHeader), /* signal */ };
    let res = await fetch(path, body ? raw : rawNoBody);
    // let res = await fetch(`${ORDERING_API_URL}/${path}`, body ? raw : rawNoBody);

    let resJSON;
    if (res !== undefined && (res.status === 200 || res.status === 202)) {
        resJSON = toText ? await res?.text() : await res?.json();
    } else resJSON = await res?.text();
    opts?.ref !== undefined && showAlert(opts?.ref, res, res?.status, resJSON);
    return { code: res?.status, res: resJSON, url: res?.url };
};

const DELETEreq = async (path: string, token?: string, toText?: boolean, opts?: { /* signal: AbortSignal; */ ref?: RefObject<AlertIcType> }) => {
    // var signal = opts?.signal || sgnl;
    let raw = { method: 'DELETE', headers: headR(token), /* signal */ };
    let res = await fetch(path, raw);
    // let res = await fetch(`${ORDERING_API_URL}/${path}`, raw);
    let resJSON;
    if (res !== undefined && (res.status === 200 || res.status === 202)) {
        resJSON = toText ? await res?.text() : await res?.json();
    } else resJSON = await res?.text();

    opts?.ref !== undefined && showAlert(opts?.ref, res, res?.status, resJSON);
    return { code: res?.status, res: resJSON };
};

const PATCHreq = async (path: string, body?: any, token?: string, toText?: boolean, opts?: { /* signal: AbortSignal; */ ref?: RefObject<AlertIcType> }) => {
    // var signal = opts?.signal || sgnl;

    // var myHeaders = new Headers();
    // myHeaders.append("Authorization", `Bearer ${token}`);
    // myHeaders.append("Content-Type", "application/json");

    /*   var requestOptions = {
        method: 'PATCH',
        headers: myHeaders,
        body: body,
        redirect: 'follow'
      }; */

    let raw = { method: 'PATCH', headers: headR(token), body: JSON.stringify(body), /* signal */ };
    let res = await fetch(path, raw);
    // let res = await fetch(`${ORDERING_API_URL}/${path}`, raw);
    let resJSON;
    if (res?.ok) {
        resJSON = toText ? await res?.text() : await res?.json();
    } else resJSON = await res.text();
    (opts?.ref !== undefined && res.status > 202) && showAlert(opts?.ref, res, res?.status, resJSON);
    return { code: res?.status, res: resJSON };
};

/*--- API V1 ---*/

// const GETreqV1 = async (path: string, token?: string | undefined, toText?: boolean, opts?: { /* signal: AbortSignal; */ ref?: RefObject<AlertIcType> }) => {
//     // var signal = opts?.signal || sgnl;
//     var headers = new Headers();
//     headers.append("Authorization", `Bearer ${token}`);
//     var reqObj = token ? { method: 'GET', headers/* , signal */ } : { method: 'GET'/* , signal */ };

//     let res = await fetch(`${CONTENT_API_URL}/api/v1/${path}`, reqObj);
//     // console.log("GETres", `${CONTENT_API_URL}/api/v1/${path}`, res?.text(), token);
//     let resJSON;
//     if (res !== undefined && (res.status === 200 || res.status === 202)) {
//         resJSON = toText ? await res?.text() : await res?.json() || await res?.text();
//     } else resJSON = await res?.text();
//     showAlert(opts?.ref, res, res?.status, resJSON);
//     return { code: res?.status, res: resJSON };
// };

// const POSTreqV1 = async (path: string, body?: any, token?: string, toText?: boolean, opts?: { /* signal: AbortSignal; */ ref?: RefObject<AlertIcType> }) => {
//     // var signal = opts?.signal || sgnl;
//     let raw = {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
//         body: JSON.stringify(body),
//         /* signal */
//     };
//     let res = await fetch(`${CONTENT_API_URL}/api/v1/${path}`, raw);

//     let resJSON;
//     if (res !== undefined && (res.status === 200 || res.status === 202)) {
//         resJSON = toText ? await res?.text() : await res?.json();
//     } else resJSON = await res?.text();

//     showAlert(opts?.ref, res, res?.status, resJSON);
//     return { code: res?.status, res: resJSON, url: res?.url };
// };

// const DELETEreqV1 = async (path: string, token?: string, toText?: boolean, opts?: { /* signal: AbortSignal; */ ref?: RefObject<AlertIcType> }) => {
//     // var signal = opts?.signal || sgnl;
//     let raw = {
//         method: 'DELETE',
//         headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
//         /* signal */
//     };
//     let res = await fetch(`${CONTENT_API_URL}/api/v1/${path}`, raw);
//     let resJSON;
//     if (res !== undefined && (res.status === 200 || res.status === 202)) {
//         resJSON = toText ? await res?.text() : await res?.json();
//     } else resJSON = await res?.text();

//     showAlert(opts?.ref, res, res?.status, resJSON);
//     return { code: res?.status, res: resJSON };
// };

// const PATCHreqV1 = async (path: string, body?: any, token?: string, toText?: boolean, opts?: { /* signal: AbortSignal; */ ref?: RefObject<AlertIcType> }) => {
//     // var signal = opts?.signal || sgnl;
//     let raw = {
//         method: 'PATCH',
//         headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
//         body: JSON.stringify(body),
//         /* signal */
//     };
//     let res = await fetch(`${CONTENT_API_URL}/api/v1/${path}`, raw);
//     let resJSON;
//     if (res !== undefined && (res.status === 200 || res.status === 202)) {
//         resJSON = toText ? await res?.text() : await res?.json();
//     } else resJSON = await res?.text();
//     showAlert(opts?.ref, res, res?.status, resJSON);
//     return { code: res?.status, res: resJSON };
// }

export {
    GETreq, POSTreq, DELETEreq, PATCHreq, // GETreqV1, POSTreqV1, DELETEreqV1, PATCHreqV1,
};