import { IAppState } from "../types";

const initialState: IAppState = {
    _User: { name: '', email: "", token: "", lastUpdated: new Date().toUTCString() },
    isLoading: true, appConfig: { 'LOGIN_LOGOUT_REDIRECT_URL': "", 'MSAL_AZURE_SIGNIN_AUTHORITY': "", 'MSAL_AZUREAD_CLIENT_ID': "", 'SOPAPLURL': "", "RM_BASEURL": "", loading: true },
    intlObj: { "en-GB": {}, "de-DE": {}, "es-ES": {}, "fr-FR": {}, "ja-JP": {}, "zh-CN": {} }, loadingIntl: true
};


const defaultCurrency = "GBP";

/*--- Sidebar menu JOSN array ---*/
const sidebarData = [
    {
        menuTitle: "Home",
        menuLocal: "MENU.HOME",
        to: "/home",
        icon: "/images/Home-icon.svg",
        activeIc: "/images/Home-Active-ic.svg",
        items: [],
        linkUrl: null
    },
    {
        menuTitle: "Sales",
        menuLocal: "MENU.SALES",
        to: null,
        icon: "/images/Sales-icon.svg",
        activeIc: "/images/Sales-Active-ic.svg",
        items: [
            {
                subTitle: "Order",
                menuLocal: "MENU.ORDER",
                to: "/sales/order",
                icon: "/images/Orders-icon.svg",
                activeIc: "/images/Orders-Active-ic.svg",
                linkUrl: null
            }
        ],
        linkUrl: null
    },
    {
        menuTitle: "Support",
        menuLocal: "MENU.SUPPORT",
        to: null,
        icon: "/images/Technical-icon.svg",
        activeIc: "/images/Technical-Active-ic.svg",
        items: [
            {
                subTitle: "Zendesk",
                menuLocal: "MENU.ZENDESK",
                to: null,
                icon: "/images/StockTransfers-icon.svg",
                activeIc: "/images/StockTransfers-Active-ic.svg",
                linkUrl: "https://segensolar.zendesk.com/hc/en-us/requests/new"
            }
        ],
        linkUrl: null
    }
];

const currencyCodes = {
    "USD": { currency: "US Dollars", locale: "en-us" },
    "AED": { currency: "United Arab Emirates Dirham", locale: "ar-ae" },
    "ARS": { currency: "Argentine Pesos", locale: "es-ar" },
    "AUD": { currency: "Australian Dollars", locale: "en-au", },
    "BOB": { currency: "Bolivian Boliviano", locale: "es-ar" },
    "BRL": { currency: "Brazilian Real", locale: "pt-br" },
    "CAD": { currency: "Canadian Dollars", locale: "en-ca" },
    "CHF": { currency: "Swiss Francs", locale: "fr-FR" },
    "CLP": { currency: "Chilean Peso", locale: "es-cl" },
    "CNY": { currency: "Yuan Renminbi", locale: "zh-CN" },
    "COP": { currency: "Colombian Peso", locale: "es-co" },
    "DKK": { currency: "Denmark Kroner", locale: "da-DK" },
    "EGP": { currency: "Egyptian Pound", locale: "ar-EG" },
    "EUR": { currency: "Euros", locale: "de-DE" /*, locale: "de_AT", */ },
    "FRF": { currency: "French Francs", locale: "fr-FR" },
    "GBP": { currency: "British Pounds", locale: "en-GB" },
    "HKD": { currency: "Hong Kong Dollars", locale: "zh-hk" },
    "HRK": { currency: "Croatian Kuna", locale: "hr" },
    "IDR": { currency: "Indonesian Rupiah", locale: "id" },
    "ILS": { currency: "Israeli Shekel", locale: "ar-IL" },
    "INR": { currency: "Indian Rupee", locale: "en-in" },
    "JPY": { currency: "Japanese Yen", locale: "ja" },
    "KRW": { currency: "South Korean Won", locale: "ko-KP" },
    "MAD": { currency: "Moroccan Dirham", locale: "ar-ma" },
    "MXN": { currency: "Mexican Peso", locale: "es-mx" },
    "MYR": { currency: "Malaysian Ringgit", locale: "en-MY" },
    "NOK": { currency: "Norway Kroner", locale: "no-NO" },
    "NZD": { currency: "New Zealand Dollars", locale: "en-NZ" },
    "PEN": { currency: "Peruvian Nuevo Sol", locale: "es-pe" },
    "PHP": { currency: "Philippine Peso", locale: "en-PH" },
    "PKR": { currency: "Pakistan Rupee", locale: "en-PK" },
    "PLN": { currency: "Polish New Zloty", locale: "pl-PL" },
    "RON": { currency: "New Romanian Leu", locale: "ro-RO" },
    "RSD": { currency: "Serbian Dinar", locale: "sr-RS" },
    "RUB": { currency: "Russian Ruble", locale: "ru-RU" },
    "SAR": { currency: "Saudi Riyal", locale: "ar-SA" },
    "SEK": { currency: "Sweden Kronor", locale: "en-SE" },
    "SGD": { currency: "Singapore Dollars", locale: "zh-SG" },
    "THB": { currency: "Thai Baht", locale: "th-TH" },
    "TRY": { currency: "Turkish Lira", locale: "tr-TR" },
    "TWD": { currency: "New Taiwan Dollar", locale: "zh-tw" },
    "UAH": { currency: "Ukrainian Hryvnia", locale: "uk-UA" },
    "VEF": { currency: "Venezuela Bolivar Fuerte", locale: "es-VE" },
    "VND": { currency: "Vietnamese Dong", locale: "vi-VN" },
    "ZAR": { currency: "South African Rand", locale: "en-ZA" },
    "ESP": { currency: "Spanish (Castilian)", locale: "es-ES" },
    "SIT": { currency: "Slovenian (Slovenia)", locale: "sl-SI" },
    "SKK": { currency: "Slovak (Slovakia)", locale: "sk-SK" },
    "PTE": { currency: "Portuguese (Portugal)", locale: "pt-PT" },
    "MTL": { currency: "Maltese (Malta)", locale: "mt-MT" },
    "LTL": { currency: "Lithuanian (Lithuania)", locale: "lt-LT" },
    "LVL": { currency: "Latvian (Latvia)", locale: "lv-LV" },
    "ITL": { currency: "Italian (Italy)", locale: "it-IT" },
    "IEP": { currency: "Irish (Ireland)", locale: "ga-IE" },
    "HUF": { currency: "Hungarian (Hungary)", locale: "hu-HU" },
    "GRD": { currency: "Greek (Greece)", locale: "el-GR" },
    "FIM": { currency: "Finnish (Finland)", locale: "fi-FI" },
    "EEK": { currency: "Estonian (Estonia)", locale: "et-EE" },
    "CZK": { currency: "Czech (Czech Republic)", locale: "cs-CZ" },
    "BGN": { currency: "Bulgarian (Bulgaria)", locale: "bg-BG" },
};

const currencySymbol = {
    AED: "د.إ",
    AFN: "؋",
    ALL: "L",
    AMD: "֏",
    ANG: "ƒ",
    AOA: "Kz",
    ARS: "$",
    AUD: "$",
    AWG: "ƒ",
    AZN: "₼",
    BAM: "KM",
    BBD: "$",
    BDT: "৳",
    BGN: "лв",
    BHD: ".د.ب",
    BIF: "FBu",
    BMD: "$",
    BND: "$",
    BOB: "$b",
    BOV: "BOV",
    BRL: "R$",
    BSD: "$",
    BTC: "₿",
    BTN: "Nu.",
    BWP: "P",
    BYN: "Br",
    BYR: "Br",
    BZD: "BZ$",
    CAD: "$",
    CDF: "FC",
    CHE: "CHE",
    CHF: "CHF",
    CHW: "CHW",
    CLF: "CLF",
    CLP: "$",
    CNH: "¥",
    CNY: "¥",
    COP: "$",
    COU: "COU",
    CRC: "₡",
    CUC: "$",
    CUP: "₱",
    CVE: "$",
    CZK: "Kč",
    DJF: "Fdj",
    DKK: "kr",
    DOP: "RD$",
    DZD: "دج",
    EEK: "kr",
    EGP: "£",
    ERN: "Nfk",
    ETB: "Br",
    ETH: "Ξ",
    EUR: "€",
    FJD: "$",
    FKP: "£",
    GBP: "£",
    GEL: "₾",
    GGP: "£",
    GHC: "₵",
    GHS: "GH₵",
    GIP: "£",
    GMD: "D",
    GNF: "FG",
    GTQ: "Q",
    GYD: "$",
    HKD: "$",
    HNL: "L",
    HRK: "kn",
    HTG: "G",
    HUF: "Ft",
    IDR: "Rp",
    ILS: "₪",
    IMP: "£",
    INR: "₹",
    IQD: "ع.د",
    IRR: "﷼",
    ISK: "kr",
    JEP: "£",
    JMD: "J$",
    JOD: "JD",
    JPY: "¥",
    KES: "KSh",
    KGS: "лв",
    KHR: "៛",
    KMF: "CF",
    KPW: "₩",
    KRW: "₩",
    KWD: "KD",
    KYD: "$",
    KZT: "₸",
    LAK: "₭",
    LBP: "£",
    LKR: "₨",
    LRD: "$",
    LSL: "M",
    LTC: "Ł",
    LTL: "Lt",
    LVL: "Ls",
    LYD: "LD",
    MAD: "MAD",
    MDL: "lei",
    MGA: "Ar",
    MKD: "ден",
    MMK: "K",
    MNT: "₮",
    MOP: "MOP$",
    MRO: "UM",
    MRU: "UM",
    MUR: "₨",
    MVR: "Rf",
    MWK: "MK",
    MXN: "$",
    MXV: "MXV",
    MYR: "RM",
    MZN: "MT",
    NAD: "$",
    NGN: "₦",
    NIO: "C$",
    NOK: "kr",
    NPR: "₨",
    NZD: "$",
    OMR: "﷼",
    PAB: "B/.",
    PEN: "S/.",
    PGK: "K",
    PHP: "₱",
    PKR: "₨",
    PLN: "zł",
    PYG: "Gs",
    QAR: "﷼",
    RMB: "￥",
    RON: "lei",
    RSD: "Дин.",
    RUB: "₽",
    RWF: "R₣",
    SAR: "﷼",
    SBD: "$",
    SCR: "₨",
    SDG: "ج.س.",
    SEK: "kr",
    SGD: "S$",
    SHP: "£",
    SLL: "Le",
    SOS: "S",
    SRD: "$",
    SSP: "£",
    STD: "Db",
    STN: "Db",
    SVC: "$",
    SYP: "£",
    SZL: "E",
    THB: "฿",
    TJS: "SM",
    TMT: "T",
    TND: "د.ت",
    TOP: "T$",
    TRL: "₤",
    TRY: "₺",
    TTD: "TT$",
    TVD: "$",
    TWD: "NT$",
    TZS: "TSh",
    UAH: "₴",
    UGX: "USh",
    USD: "$",
    UYI: "UYI",
    UYU: "$U",
    UYW: "UYW",
    UZS: "лв",
    VEF: "Bs",
    VES: "Bs.S",
    VND: "₫",
    VUV: "VT",
    WST: "WS$",
    XAF: "FCFA",
    XBT: "Ƀ",
    XCD: "$",
    XOF: "CFA",
    XPF: "₣",
    XSU: "Sucre",
    XUA: "XUA",
    YER: "﷼",
    ZAR: "R",
    ZMW: "ZK",
    ZWD: "Z$",
    ZWL: "$",
};

const de_DE = "https://firebasestorage.googleapis.com/v0/b/segensolar-29931.appspot.com/o/de-DE.json?alt=media&token=44788808-c38a-4527-a6a7-450d46a4a8a3";
const en_GB = "https://firebasestorage.googleapis.com/v0/b/segensolar-29931.appspot.com/o/en-GB.json?alt=media&token=53c65a35-548f-4ff5-9226-4973a08b4c04";
const es_ES = "https://firebasestorage.googleapis.com/v0/b/segensolar-29931.appspot.com/o/es-ES.json?alt=media&token=893c1351-da18-4e84-bd75-01d5001bd06e";
const fr_FR = "https://firebasestorage.googleapis.com/v0/b/segensolar-29931.appspot.com/o/fr-FR.json?alt=media&token=3e70ba80-e9a6-4010-95f0-b7b2d51de797";
const ja_JP = "https://firebasestorage.googleapis.com/v0/b/segensolar-29931.appspot.com/o/ja-JP.json?alt=media&token=7caae688-f468-463a-8132-c5d4fa7d036a";
const zh_CN = "https://firebasestorage.googleapis.com/v0/b/segensolar-29931.appspot.com/o/zh-CN.json?alt=media&token=6f48d025-b952-447d-a70d-2a27536a8cff";


const appConfigLabel = process.env.REACT_APP_APPCONFIG_LABEL || "SOMS";
console.log("appConfigLabel", appConfigLabel);
const allAppConfigKeys = [
    { key: "SOPAPI_URL", label: appConfigLabel },
    { key: "MSAL_AZUREAD_CLIENT_ID", label: appConfigLabel },
    { key: "LOGIN_LOGOUT_REDIRECT_URL", label: appConfigLabel },
    { key: "MSAL_AZURE_SIGNIN_AUTHORITY", label: appConfigLabel },
    { key: "RM_BASEURL", label: appConfigLabel }
];

export {
    initialState,
    sidebarData, defaultCurrency, currencyCodes, currencySymbol,
    allAppConfigKeys, en_GB, de_DE, es_ES, fr_FR, ja_JP, zh_CN
};