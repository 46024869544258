import { useContext, useState, forwardRef, Ref, useImperativeHandle, useEffect, Fragment, useRef } from "react";
// import { OrdersData } from "../../fake_data";
// import Popup from "../../components/popup/Popup";
import { PageLoader } from "../../components";
import { AppContext, /* resetUser, */ set_LastUpdated } from "../../context";
import { snunType } from "../../types";
import dayjs from "dayjs";
import { AuthProviderFunc, /* onLogout, */ toIntlPrice } from "../../functions";
import localizedFormat from 'dayjs/plugin/localizedFormat';
// eslint-disable-next-line
import locale_de from 'dayjs/locale/de';
// eslint-disable-next-line
import locale_es from 'dayjs/locale/es';
import locale_gb from 'dayjs/locale/en-gb';
import { useIntlMsg } from "../../i18n";
import { defBucketArr } from "../../fake_data";
import { useAPI } from "../../hooks";

dayjs.extend(localizedFormat);
//the next line has to come from user preference
dayjs.locale(locale_gb);

export interface OrdersType {
    getOrders: (n: number, isReset: boolean) => void;
    onReset: () => void;
};

interface Props {
    isStock: boolean;
    orderProcessor: snunType;
    stockLocationId: snunType;
    isReseted: boolean;
    setIsReseted: (v: boolean) => void;
    setLoading: (v: boolean) => void;
};

interface defBucketDataType {
    order: number;
    data: any[];
    subtitle: string;
    info: {
        title: string;
        data: {
            title: string;
            data: string[];
        }[];
    }[];
    shipping_alc_Des: boolean;
}

const Orders = forwardRef(({ isStock, orderProcessor, stockLocationId, setLoading, isReseted, setIsReseted }: Props, ref: Ref<OrdersType>) => {
    const { GETreq } = useAPI();
    const { state: { appConfig }, dispatch } = useContext(AppContext);
    // eslint-disable-next-line
    const authProvider = AuthProviderFunc({ appConfig });
    let { t } = useIntlMsg();

    // eslint-disable-next-line
    // const [moreDetail, setMoreDetail] = useState<number | null>(null);
    // const updateMoreDetail = (index: number) => {
    //   index === moreDetail ? setMoreDetail(null) : setMoreDetail(index);
    // };
    const { data: defBucketData } = defBucketArr;
    const [bucketData, setBucketData] = useState<defBucketDataType[]>([]);

    useEffect(() => {
        if (isReseted) setBucketData(defBucketData);
        // eslint-disable-next-line
    }, [isReseted]);

    useImperativeHandle(ref, () => ({ getOrders, onReset }));

    const [selCatArr, setSelCatArr] = useState<number[]>([0]);
    const [loadingBucket, setLoadingBucket] = useState<number>(-1);
    
    const preventMultiple = useRef(false);
    const unAuthFun = async () => {
        if (!preventMultiple.current && window.confirm(t("ERR.SESSION_EXPIRE")) === true) {
            window?.location?.reload();
            //  localStorage.removeItem("@User");
            //  resetUser(dispatch); onLogout(authProvider);
        };
    };

    const handleToggle = (idx: number) => {
        // setMoreDetail(null);
        if (selCatArr.includes(idx)) { setSelCatArr(selCatArr.filter((i) => i !== idx)); }
        else { setSelCatArr([...selCatArr, idx]); }
        // check this order prority bucket is empty or not
        if (bucketData[idx]?.data?.length === 0) { setLoadingBucket(idx); getOrders(idx + 1); };
    };

    const category = (i: number) => selCatArr.includes(i);
    const [selInfoArr, setSelInfoArr] = useState<number[]>([]);

    const handleInfoToggle = (idx: number) => {
        if (selInfoArr.includes(idx)) setSelInfoArr(selInfoArr.filter((i) => i !== idx));
        else setSelInfoArr([...selInfoArr, idx]);
    }; // toggle information section

    const infoToggle = (i: number) => selInfoArr.includes(i); // toggle inofrmation section
    const closeInfo = (Idx: number) => setSelInfoArr(selInfoArr.filter((i) => i !== Idx)); // close information section

    const getOrders = async (n: number = 1, isReset = false) => {
        const oUrl = `OrdersList/OrdersInPriorityBucket${n.toString()}?UserId=${orderProcessor}&StockLocationId=${stockLocationId}`;
        let tempOrd = isReset ? [...defBucketData] : [...bucketData];
        await GETreq(oUrl).then(({ code, res }) => {
            if (code === 200 || code === 202) {
                var cloneRes = [...res];
                const sortedRes = cloneRes.sort((a, b) => {
                    const aDate = new Date(a?.dispatchDate);
                    const bDate = new Date(b?.dispatchDate);
                    return aDate.getTime() - bDate.getTime();
                });
                tempOrd[n - 1].data = sortedRes as any;
                setBucketData(tempOrd);
                    /* !isReseted &&  */ setIsReseted(false);
                const lastUpdated = new Date().toUTCString();
                localStorage.setItem("@lastUpdated", lastUpdated);
                set_LastUpdated(dispatch, lastUpdated)
            } else if (code === 401) { unAuthFun(); }
            onOff();
            isReset && setSelCatArr([0]);
        }).catch((e) => { console.error("OrdProcErr:", e); onOff(); });
    };

    function onOff() { setLoading(false); setLoadingBucket(-1); };
    function onReset() { setIsReseted(true); setBucketData([]); setSelCatArr([0]); };

    function onSort(sortOf: string, isDes: boolean, idx: number) {
        let tempOrd = [...bucketData];
        const sortedRes = tempOrd[idx]?.data?.sort((a, b) => {
            const aDate = new Date(isDes ? b?.dispatchDate : a?.dispatchDate);
            const bDate = new Date(isDes ? a?.dispatchDate : b?.dispatchDate);
            return bDate.getTime() - aDate.getTime();
        });
        tempOrd[idx].data = sortedRes;
        tempOrd[idx].shipping_alc_Des = !isDes;
        setBucketData(tempOrd);
    }

    return (
        <>
            {isStock === true ? (
                isReseted === true ? (<></>) : (
                    bucketData?.map((item, index) => {
                        // orderBktData?.map((item, index) => {
                        return (
                            <section key={index} className="orders mb-3">
                                <section className="priority">
                                    <div className="priority_">
                                        <div className="priority_flex-row">
                                            <span className="priority_flex-row-bold">{t("ORDER.PRIORITY")} {item?.order}</span>
                                            <img src="/images/Priority-icon.svg" alt="icon" onClick={() => handleInfoToggle(index)} />
                                        </div>
                                        <span className="priority_flex-row-text-subtitle my-1">{item?.subtitle && t(item?.subtitle)}</span>
                                        <div className="priority_flex-row-text">
                                            <span>{t("TOTAL_ORDERS")} :{" "}{item?.data?.length === 0 ? "--" : item?.data?.length}</span>
                                        </div>
                                    </div>
                                    <div className="priority__ ms-3" onClick={() => handleToggle(index)}>
                                        <img src="/images/DropDown-icon.svg" alt="icon"
                                            style={{ transform: category(index) ? "rotate(0)" : "rotate(180deg)", transition: "all 0.2s linear" }}
                                        />
                                    </div>
                                </section>
                                {/* ================================== Category ========================================== */}
                                {/* {info === true ? ( */}
                                <section>
                                    {/* {infoToggle(index) ? ( */}
                                    <div className={`${infoToggle(index) ? "panel-collapse" : "panel-collapse panel-close"}`}>
                                        <div className="orders-info-title">
                                            <div className="orders-info-title_flex-">
                                                <span className="orders-info-name">
                                                    {t("WHAT_IS_PRIORITY")} {item?.order}?
                                                </span>
                                                <img src="/images/Exit-icon.svg" alt="icon" onClick={() => closeInfo(index)} />
                                            </div>
                                            <div className="orders-info-pre">
                                                <div className="orders-info-pre">
                                                    <ol type="1" >
                                                        {item?.info?.map((itm, idx: number) => (
                                                            <Fragment key={idx}>
                                                                <li className="my-2">{itm.title && t(itm.title)}</li>
                                                                <ol type="a">
                                                                    {itm?.data &&
                                                                        itm?.data?.map((iteem, idxx: number) => (
                                                                            <Fragment key={idxx}>
                                                                                <li className="my-1">{iteem?.title && t(iteem?.title)}</li>
                                                                                <ol type="i">
                                                                                    {iteem?.data && iteem?.data?.map((itemm: any, iddx: number) => (
                                                                                        <li key={iddx}>{itemm && t(itemm)}</li>
                                                                                    ))}
                                                                                </ol>
                                                                            </Fragment>
                                                                        ))}
                                                                </ol>
                                                            </Fragment>
                                                        ))}
                                                    </ol>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* ) : // )
                                        null} */}
                                </section>
                                {category(index) ? (
                                    <>
                                        {/* ============================= Table ======================================== */}
                                        <section className="orders-data">
                                            <div className="table-responsive">
                                                <section className="table-info">
                                                    {loadingBucket === index ? (
                                                        <section className="loader_center">
                                                            <PageLoader isLoading isSmall />
                                                        </section>
                                                    ) : (
                                                        <table className="table table-row-bordered table-row-gray-100 align-middle gs-2 gy-2">
                                                            <thead className="table-title">
                                                                <tr className="fw-bold text-muted">
                                                                    <th className="min-w-100px pb-4 ps-4">{t("ORDER.TAS_CODE")}</th>
                                                                    <th className="min-w-100px pb-4 ">{t("ORDER.ORD_NO")}</th>
                                                                    <th className="min-w-100px pb-4">{t("ORDER.ORD_DATE")}</th>
                                                                    <th className="min-w-125px pb-4">{t("ORDER.CUSTOMER")}</th>
                                                                    <th className="min-w-140px pb-4">{t("ORDER.ORD_DETAILS")}</th>
                                                                    <th className="min-w-80px pb-4">{t("ORDER.VALUE")}</th>
                                                                    <th className="min-w-100px pb-4">{t("ORDER.STATUS")}</th>
                                                                    <th className="min-w-120px pb-4">
                                                                        <button className="btn-none fw-bold text-muted text-uppercase d-flex flex-row w-100" onClick={() => onSort("SHIPPING_ALC", item.shipping_alc_Des, index)}>
                                                                            {t("ORDER.SHIPPING_ALC")}
                                                                            <img
                                                                                src="/images/Shorting-icon.svg"
                                                                                alt="icon"
                                                                                className={`ms-1 ${item.shipping_alc_Des ? "sort-ic" : "twoS-transition"}`}
                                                                            />
                                                                        </button>
                                                                    </th>
                                                                    <th className="min-w-100px pb-4">{t("ORDER.STOCK")}</th>
                                                                    {/* <th className="min-w-80px pb-4">Move</th> */}
                                                                </tr>
                                                            </thead>
                                                            {item?.data?.length > 0 ? (
                                                                item?.data?.map(
                                                                    (itemOD: any, index: number) => {
                                                                        return (
                                                                            <tbody key={index} className="table-data">
                                                                                <tr>
                                                                                    <td>
                                                                                        <div className="order-text1 fs-6  mt-3 ps-4">
                                                                                            {itemOD?.customerShortCode ? <a target="_blank" href={'http://' + appConfig.RM_BASEURL + '/orders/account.asp?TASCode=' + itemOD?.customerShortCode} rel="noreferrer">{itemOD?.customerShortCode}</a> : "--"}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="order-text1 d-block mt-3 fs-6">
                                                                                            <a target="_blank" href={'http://' + appConfig.RM_BASEURL + '/orders/order_details.asp?OrderType=PV%20Equipment%20Sales&Project_ID=' + itemOD?.orderId} rel="noreferrer">{itemOD?.orderCode || "--"}</a>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="order-text1 d-block mt-3 fs-6">{itemOD?.orderDate ? dayjs(itemOD?.orderDate).format("DD-MMM-YYYY") : "--"}</div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="fw-bold order-text2 d-block mt-3 fs-6">{itemOD?.customerName || "--"}</div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="order-text3 d-block mt-3 fs-6">{itemOD?.summary || "--"}</div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="order-text4 d-block mt-3 fs-6">{toIntlPrice({ "amount": itemOD?.orderValue })}</div>
                                                                                    </td>
                                                                                    {/* <td>{itemOD.Margin}</td> */}
                                                                                    <td>
                                                                                        <div className="order-text2 d-block mt-3 fs-6">{itemOD?.status || "--"}</div>
                                                                                        <span className="order-text1 d-block fs-7">{itemOD?.StatusA}</span>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="shipping-box mt-3 fs-6">
                                                                                            <span className="order-text4">{itemOD?.dispatchDate ? dayjs(itemOD?.dispatchDate).format("DD-MMM-YYYY") : "--"}</span>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="d-block mt-3 fs-6 d-flex justify-content-center align-items-center">
                                                                                            {/* <span className="order-text4 me-2">{itemOD.Stock}</span> */}
                                                                                            <div className="stock-box__"    /* {checkStockStaus(itemOD.StockI)} */
                                                                                                style={{ background: itemOD?.stockStatusColour === "#FFFFFF" ? "#f0f0f0" : itemOD?.stockStatusColour }}
                                                                                            >
                                                                                                <span className="order-text6">{itemOD.stockStatus || "--"}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    {/*--- hide/bypass this feature for now, as we discussed ---*/}
                                                                                    {/* <td> div className="mt-3"> <Popup /> </div> </td> */}
                                                                                </tr>
                                                                                {/*--- hide/bypass this feature for now, as we discussed ---*/}
                                                                                {/* <tr>
                                                                                    <td /> <td />
                                                                                        <td
                                                                                        colSpan={2}
                                                                                        className="pb-4 w500px">
                                                                                        {moreDetail === index ? (
                                                                                            <>
                                                                                                <div>
                                                                                                    <span className="order-text5">
                                                                                                    {itemOD.Info}
                                                                                                    </span>
                                                                                                    <br></br>
                                                                                                    <span className="view-text">
                                                                                                    New in Stock :
                                                                                                    {itemOD.ViewDetails}
                                                                                                    </span>
                                                                                                    <br></br>
                                                                                                </div>
                                                                                            <br></br>
                                                                                            </>
                                                                                        ) : null}
                                                                                        <span className="order-text7" onClick={() => updateMoreDetail(index)}>
                                                                                            {moreDetail === index ? "View less details" : "View more details"}
                                                                                        </span>
                                                                                        <img
                                                                                            src="/images/View-icon.svg"
                                                                                            alt="icon"
                                                                                            className="ms-2"
                                                                                            style={{
                                                                                            transform:
                                                                                                index === moreDetail
                                                                                                ? "rotate(180deg)"
                                                                                                : "rotate(0)",
                                                                                            transition: "all 0.2s linear",
                                                                                            }}
                                                                                        />
                                                                                    </td> <td /> <td /> <td /> <td /> <td /> <td />
                                                                                </tr> */}
                                                                            </tbody>
                                                                        );
                                                                    }
                                                                )
                                                            ) : (
                                                                <tbody className="bg-white">
                                                                    <tr>
                                                                        <td />
                                                                        <td />
                                                                        <td />
                                                                        <td />
                                                                        <td colSpan={2}>
                                                                            <h3 className="text-muted fw-bold my-5 py-5">{t("NO_DATA_FOUND")}</h3>
                                                                        </td>
                                                                        <td />
                                                                        <td />
                                                                        <td />
                                                                    </tr>
                                                                </tbody>
                                                            )}
                                                        </table>
                                                    )}
                                                </section>
                                            </div>
                                        </section>
                                    </>
                                ) : null}
                            </section>
                        );
                    })
                )
            ) : (
                null
                /*--- hide/bypass this feature for now, as we discussed ---*/
                // <section className="orders  mb-3">
                //   <section className="priority">
                //     <div className="priority_">
                //       <div className="priority_flex-row">
                //         <span> Orders</span>
                //         {/* <img
                //               src="/images/Priority-icon.svg"
                //               alt="icon"
                //               onClick={() => handleOpen()}
                //             /> */}
                //       </div>
                //       <div className="priority_flex-row-text">
                //         <span>Total Orders </span>
                //       </div>
                //     </div>
                //   </section>
                //   {/* ================================== Category ========================================== */}
                //   <section className="orders-data">
                //     <div className="table-responsive">
                //       <section className="table-info">
                //         <table className="table table-row-bordered table-row-gray-100 align-middle gs-2 gy-2">
                //           <>
                //             <thead className="table-title">
                //               <tr className="fw-bold text-muted">
                //                 <th className="min-w-100px pb-4 ps-4">TAS Code</th>
                //                 <th className="min-w-100px pb-4 ">Order No</th>
                //                 <th className="min-w-100px pb-4">Order date</th>
                //                 <th className="min-w-125px pb-4">Customer</th>
                //                 <th className="min-w-140px pb-4">Order Details</th>
                //                 <th className="min-w-80px pb-4">value</th>
                //                 {/* <th className="min-w-80px pb-4">
                //               margin
                //             </th> */}
                //                 <th className="min-w-100px pb-4">Status</th>
                //                 <th className="min-w-120px pb-4">Shipping Alc</th>
                //                 <th className="min-w-100px pb-4">Stock</th>
                //                 {/* <th className="min-w-80px pb-4">Move</th> */}
                //               </tr>
                //             </thead>
                //             {OrdersData.map((item, index) => {
                //               return item.OrderDetails.map((itemOD, index) => {
                //                 return (
                //                   <tbody
                //                     key={index}
                //                     className="table-data">
                //                     <tr>
                //                       <td>
                //                         <div className="order-text1 fs-6  mt-3 ps-4">
                //                           {itemOD.TasCode}
                //                         </div>
                //                       </td>
                //                       <td>
                //                         <div className="order-text1 d-block mt-3 fs-6">
                //                           {itemOD.OrderNo}
                //                         </div>
                //                       </td>
                //                       <td>
                //                         <div className="order-text1 d-block mt-3 fs-6">
                //                           {itemOD.OrderDate}
                //                         </div>
                //                       </td>
                //                       <td>
                //                         <div className="fw-bold order-text2 d-block mt-3 fs-6">
                //                           {itemOD.Customer}
                //                         </div>
                //                       </td>
                //                       <td>
                //                         <div className="order-text3 d-block mt-3 fs-6">
                //                           {itemOD.OrderDetail}
                //                         </div>
                //                       </td>
                //                       <td>
                //                         <div className="order-text4 d-block mt-3 fs-6">
                //                           £{itemOD.Value}
                //                         </div>
                //                       </td>
                //                       {/* <td>{itemOD.Margin}</td> */}
                //                       <td>
                //                         <div className="order-text2 d-block mt-3 fs-6">
                //                           {itemOD.Status}
                //                         </div>
                //                         <span className="order-text1 d-block fs-7">
                //                           {itemOD.StatusA}
                //                         </span>
                //                       </td>
                //                       <td>
                //                         <div className="shipping-box mt-3 fs-6">
                //                           <span className="order-text4">
                //                             {itemOD.Shipping}
                //                           </span>
                //                         </div>
                //                       </td>
                //                       <td>
                //                         <div className="d-block mt-3 fs-6 d-flex justify-content-center align-items-center">
                //                           {/* <span className="order-text4 me-2">
                //                         {itemOD.Stock}
                //                       </span> */}
                //                           <div
                //                             className={checkStockStaus(itemOD.StockI)}
                //                           >
                //                             <span className="order-text6">
                //                               {itemOD.StockI}
                //                             </span>
                //                           </div>
                //                         </div>
                //                       </td>
                //                       {/* <td>
                //                         <div className="mt-3">
                //                           <Popup />
                //                         </div>
                //                       </td> */}
                //                     </tr>
                //                     {/* ============================================================ */}
                //                     {/* <tr>
                //                   <td></td>
                //                   <td></td>
                //                   <td></td>
                //                   <td colSpan={2}>
                //                     <span className="order-text5">{itemOD.Info}</span>
                //                   </td>
                //                   <td></td>
                //                   <td></td>
                //                   <td></td>
                //                   <td></td>
                //                   <td></td>
                //                   <td></td>
                //                 </tr> */}
                //                     {/* ============================================================== */}
                //                     {/* <tr>
                //                       <td></td>
                //                       <td></td>
                //                       <td
                //                         colSpan={2}
                //                         className="pb-4 w500px"
                //                       >
                //                         {moreDetail === index ? (
                //                           <>
                //                             <div>
                //                               <span className="order-text5">
                //                                 {itemOD.Info}
                //                               </span>
                //                               <br></br>
                //                               <span className="view-text">
                //                                 New in Stock :{itemOD.ViewDetails}
                //                               </span>
                //                               <br></br>
                //                             </div>
                //                             <br></br>
                //                           </>
                //                         ) : null}
                //                         <span
                //                           className="order-text7"
                //                           onClick={() => updateMoreDetail(index)}
                //                         >
                //                           {moreDetail === index
                //                             ? "View less details"
                //                             : "View more details"}
                //                         </span>
                //                         <img
                //                           src="/images/View-icon.svg"
                //                           alt="icon"
                //                           className="ms-2"
                //                           style={{
                //                             transform:
                //                               index === moreDetail
                //                                 ? "rotate(180deg)"
                //                                 : "rotate(0)",
                //                             transition: "all 0.2s linear",
                //                           }}
                //                         />
                //                       </td>
                //                       <td></td>
                //                       <td></td>
                //                       <td></td>
                //                       <td></td>
                //                       <td></td>
                //                       <td></td>
                //                     </tr> */}
                //                   </tbody>
                //                 );
                //               });
                //             })}
                //           </>
                //         </table>
                //       </section>
                //     </div>
                //   </section>
                // </section>
            )}
        </>
    );
});

export default Orders;

// let defaultBucketData = [{ order: 1, data: [] }, { order: 2, data: [] }, { order: 3, data: [] }, { order: 4, data: [] }, { order: 5, data: [] }, { order: 6, data: [] }, { order: 7, data: [] }, { order: 8, data: [] }, { order: 9, data: [] }, { order: 10, data: [] }, { order: 11, data: [] }, { order: 12, data: [] }, { order: 13, data: [] }, { order: 14, data: [] },]